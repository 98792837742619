import React from 'react';
import './AuthLoading.scss';
import LoaderSpinner from "../../shared/ui-components/loader-spinner/LoaderSpinner";

interface ForgotPasswordLoadingProps {
    description: string;
}

function AuthLoading(props: ForgotPasswordLoadingProps) {
    const {description} = props;

    return (
        <div className={'AuthLoading'}>
            <LoaderSpinner></LoaderSpinner>
            <div className={'AuthLoading__description'}>{description || ''}</div>

        </div>
    );
}

export default AuthLoading;
