import React from 'react';
import './CastingStatusConnecting.scss'
import LoaderSpinner from "../../shared/ui-components/loader-spinner/LoaderSpinner";

function CastingStatusConnecting() {

    return (
        <div className={'CastingStatusConnecting'}>
            <LoaderSpinner></LoaderSpinner>
            <span className={'CastingStatusConnecting__description'}>Connecting to headset</span>
            <img alt={'connection'} src={'assets/images/casting-connection.svg'} />
        </div>
    );
}

export default CastingStatusConnecting;
