import React, {useState} from 'react';
import './LoginContainer.scss';
import Login from "./Login/Login";
import {useNavigate} from "react-router-dom";
import {login} from "../../shared/http/auth.http";
import * as Sentry from "@sentry/react";
import {StorageService} from "../../shared/helpers/storageService";
import {Mixpanel} from "../../shared/services/mixpanel";
import {SnackbarTypes, useSnackbarSettings} from "../../shared/ui-components/snackbar/SnackbarContext";
import {CastingRoutes} from "../../App";
import AuthLoading from "../AuthLoading/AuthLoading";

export interface User {
    "access_token": string;
    "user_id": number;
    "role": UserRole;
    "clinic_id": number;
    "clinic_name": string;
    "first_name": string;
    "last_name": string;
    "casting": {
        "deviceSn": string;
        "castingToken": string;
        "castingTokenExpires": number;
        "castingTokenLifeTimeHours": number;
        "pubNubPublishKey": string;
        "pubNubSubscribeKey": string;
        "iceServers": [
            {
                "urls": string[];
            },
            {
                "username": string;
                "credential": string;
                "urls": string[];
            }
        ]
    }
}

export enum UserRole {
    ROLE_DOCTOR = 'ROLE_DOCTOR',
    ROLE_REFERRING_DOCTOR = 'ROLE_REFERRING_DOCTOR',
    ROLE_PATIENT = 'ROLE_PATIENT',
    ROLE_OFFICE_USER = 'ROLE_OFFICE_USER',
    ROLE_TECHNICAL_SUPPORT = 'ROLE_TECHNICAL_SUPPORT'
}


function LoginContainer() {
    let navigate = useNavigate();
    const {saveSnackbarSettings} = useSnackbarSettings();

    const [error, setError] = useState('');
    const [showReCaptcha, setShowReCaptcha] = useState(false);
    const [loading, setLoading] = useState(false);

    const submitLogin = (username: string, password: string, reCaptcha?: string) => {
        setLoading(true);
        login(username, password, reCaptcha).then((res: User) => {
            setLoading(true);
            if (res.access_token && res.user_id && res.role === UserRole.ROLE_PATIENT) {
                // res.casting.deviceSn = 'EMULATOR31X2X10X0';

                const user = {
                    user_id: res.user_id,
                    role: res.role,
                    clinic_id: res.clinic_id,
                    clinic_name: res.clinic_name,
                    first_name: res.first_name,
                    last_name: res.last_name,
                    casting: res.casting,
                }

                //set user data for errors logs
                Sentry.setUser(user);

                StorageService.setAccessToken(res.access_token);
                StorageService.setUser(user);
                StorageService.setLoginTimestamp(new Date().getTime());

                Mixpanel.track('Casting Login', {
                    'Login status': true,
                    Message: 'success',
                    DeviceSN: res.casting.deviceSn,
                    Type: res.role
                });

                navigate(`/${CastingRoutes.STATUS}`, {replace: true})
            } else if (res.role && res.role !== UserRole.ROLE_PATIENT) {
                setError('It looks like you tried to login with therapist credentials.');
                Mixpanel.track('Casting Login', {
                    'Login status': false,
                    Message: 'It looks like you tried to login with therapist credentials.',
                    DeviceSN: res?.casting?.deviceSn,
                    Type: res.role
                });
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e);
            if (e.showCaptcha) {
                setShowReCaptcha(true);
            }
            if (e.message && e.message === 'Incorrect username or password.') {
                setError('Invalid username or password.');
            } else {
                saveSnackbarSettings({
                    title: 'Login failed',
                    description: e.message || 'There seems to be an issue with our servers. Please try again later.',
                    type: SnackbarTypes.ERROR,
                    icon: window.location.origin + '/assets/images/icons/notification-error.svg',
                });
            }
            Mixpanel.track('Casting Login', {
                'Login status': false,
                Message: e.message,
                DeviceSN: '',
                Type: ''
            });
        });
    }

    return (
        <>
            {
                loading ?
                    <AuthLoading description={''}></AuthLoading> :
                    <Login submitLogin={submitLogin} error={error} showReCaptcha={showReCaptcha}></Login>
            }
        </>
    );
}

export default LoginContainer;
