import './Dropdown.scss';
import React from "react";

interface DropdownProps {
    submenus: {
        title: string,
        icon?: string
    }[],
    dropdown: boolean,
    onItemClick: (e: any, number: number) => void
}

const Dropdown = (props: DropdownProps) => {

    const {submenus, dropdown, onItemClick} = props;

    return (
        <ul className={`dropdown ${dropdown ? "show" : ""}`}>
            {submenus.map((submenu, index) => (
                <li key={index} className="menu-items" onClick={(e) => onItemClick(e, index)}>
                    <img src={submenu.icon} alt={'icon'}/>
                    <span>{submenu.title}</span>
                </li>
            ))}
        </ul>
    );
};

export default Dropdown;
