import React, {useEffect, useRef, useState} from 'react';
import './Restricted.scss';
import CastingContainer from "../casting-container/CastingContainer";
import Header from "../shared/ui-components/header/Header";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import CastingStatusContainer from "../casting-status-container/CastingStatusContainer";
import {SnackbarTypes, useSnackbarSettings} from "../shared/ui-components/snackbar/SnackbarContext";
import {StorageService} from "../shared/helpers/storageService";
import PubNub, {generateUUID} from 'pubnub';
import {PubNubProvider} from 'pubnub-react';
import {ErrorsTypes} from "../errors-view-container/ErrorsViewContainer";
import * as Sentry from "@sentry/react";
import {Mixpanel} from "../shared/services/mixpanel";
import {isMobile} from "react-device-detect";
import {CastingRoutes} from "../App";

function Restricted() {
    const navigate = useNavigate();
    const location = useLocation();
    const {saveSnackbarSettings} = useSnackbarSettings();

    const [pubnub, setPubnub] = useState<any>();
    const [pubnubMessage, setPubnubMessage] = useState<PubNub.MessageEvent>();
    const [pubnubPresence, setPubnubPresence] = useState<PubNub.PresenceEvent>();

    const isFirstRun = useRef(true);

    const autoLogoutTime = 1000 * 60 * 60;
    const isIframe = window.location !== window.parent.location;

    const clearSnackbar = () => {
        saveSnackbarSettings({
            time: 10,
            title: '',
            description: '',
            type: SnackbarTypes.WARNING,
        });
    }

    const handleAutoLogout = () => {
        Mixpanel.track('Log Out');
        clearSnackbar();
        StorageService.clear();
        Sentry.captureMessage("Session expired");
        navigate(`/${CastingRoutes.ERROR}/` + ErrorsTypes.EXPIRE_LOGIN);
    }

    const pubnubListener = {
        message: (messageEvent: PubNub.MessageEvent) => {
            setPubnubMessage(messageEvent);
        },
        presence: (presenceEvent: PubNub.PresenceEvent) => {
            setPubnubPresence(presenceEvent);
        }
    }


    useEffect(() => {
        const token = StorageService.getAccessToken();
        const user = StorageService.getUser();

        if (!token || !user) {
            StorageService.clear();
            navigate(`/${CastingRoutes.LOGIN}`, {replace: true})
            return;
        }

        setTimeout(() => {
            // @ts-ignore
            window.wootricSettings = {
                // @ts-ignore
                ...window.wootricSettings,
                // @ts-ignore
                email: window.wootricSettings.email ?? user?.user_id ?? StorageService.getMixpanelId() ?? '',
            }
        }, 0);

        const pubnubInstance = new PubNub({
            publishKey: user.casting.pubNubPublishKey,
            subscribeKey: user.casting.pubNubSubscribeKey,
            uuid: StorageService.getPubnubId() ? StorageService.getPubnubId() : generateUUID(),
        });

        pubnubInstance.addListener(pubnubListener);

        pubnubInstance.subscribe({channels: [user.casting.deviceSn], withPresence: true});

        StorageService.setPubnubId(pubnubInstance.getUUID());

        setPubnub(pubnubInstance);

        if (isFirstRun.current) {
            isFirstRun.current = false;
            navigate(`/${CastingRoutes.STATUS}`, {replace: true});
        }

        const autoLogoutTimeout = setTimeout(handleAutoLogout, autoLogoutTime);

        return (() => {
            clearTimeout(autoLogoutTimeout);
            pubnubInstance.unsubscribeAll();
            pubnubInstance.removeListener(pubnubListener)
        })
    }, [])

    return (
        <>
            {
                pubnub &&
                <PubNubProvider client={pubnub}>
                    <div className="Restricted">

                        {
                            !(isMobile && location.pathname === '/display') && !isIframe && <Header></Header>
                        }

                        <Routes>
                            <Route path="display" element={<CastingContainer pubnubMessage={pubnubMessage}
                                                                             pubnubPresence={pubnubPresence}/>}/>
                            <Route path="status/*" element={<CastingStatusContainer/>}/>
                        </Routes>
                    </div>
                </PubNubProvider>
            }
        </>
    );
}

export default Restricted;
