import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Routes, useLocation} from "react-router-dom";
import Restricted from "./Restricted/Restricted";
import AuthContainer from "./login-container/AuthContainer";
import Snackbar from "./shared/ui-components/snackbar/Snackbar";
import {SnackbarProvider, SnackbarTypes} from "./shared/ui-components/snackbar/SnackbarContext";
import ErrorsViewContainer from "./errors-view-container/ErrorsViewContainer";
import * as Sentry from "@sentry/react";
import AppServerError from "./errors-view-container/app-server-error/AppServerError";
import {StorageService} from "./shared/helpers/storageService";
import {getCastingConfig} from "./shared/http/config.http";
import Wootric from './shared/analytics/Wootric';

export enum CastingRoutes {
    LOGIN = 'login',
    FORGOT_PASSWORD = 'forgot-password',
    STATUS = 'status',
    CASTING = 'display',
    ERROR = 'error'
}

function App() {
    const [isLoading, setIsLoading] = useState(true);

    const location = useLocation();

    const settings = {
        title: '',
        description: '',
        type: SnackbarTypes.INFO,
    };

    const onLoadedInIFrame = () => {
        const webPortalUrl = `${process.env.REACT_APP_WEB_PORTAL_URL}`;
        console.log('webPortalUrl - ', webPortalUrl);
        window.parent.postMessage('getPatientInfo', webPortalUrl);
        window.onmessage = e => {
            if (e.origin === webPortalUrl) {
                setIsLoading(false);

                const patient = e.data;

                const user = {
                    user_id: patient.user_id,
                    role: patient.role,
                    clinic_id: patient.clinic_id,
                    first_name: patient.first_name,
                    last_name: patient.last_name,
                    casting: patient.casting,
                }

                //set user data for errors logs
                Sentry.setUser(user);

                StorageService.setAccessToken(patient.access_token);
                StorageService.setUser(user);
                StorageService.setUserLocation(patient.location);
            }
        };
    }

    useEffect(() => {
        if ( window.location !== window.parent.location ) {
            onLoadedInIFrame();
        } else {
            const user = StorageService.getUser();

            if (user) {
                //set user data for errors logs
                Sentry.setUser(user);
            }

            setIsLoading(false);
            getCastingConfig().then(config => StorageService.setCastingConfig(config));
        }
    }, [])

    return (
        <Sentry.ErrorBoundary
            fallback={() => {
                return <>
                    {
                        location.pathname.includes(`/${CastingRoutes.LOGIN}`) ?
                            <AppServerError></AppServerError> :
                            <ErrorsViewContainer></ErrorsViewContainer>
                    }
                </>
            }}
        >
            <SnackbarProvider settings={settings}>
                {
                    !isLoading &&
                    <Routes>
                        <Route path={`${CastingRoutes.LOGIN}/*`} element={<AuthContainer/>}/>
                        <Route path={`${CastingRoutes.ERROR}/*`} element={<ErrorsViewContainer/>}/>
                        <Route path="*" element={<Restricted/>}/>
                    </Routes>
                }
                <Snackbar></Snackbar>
            </SnackbarProvider>
            <Wootric/>
        </Sentry.ErrorBoundary>
    );
}

export default App;
