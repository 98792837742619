import React, {useEffect, useState} from 'react';
import './ForgotPasswordSendEmail.scss';
import TextInput from "../../../shared/ui-components/text-input/TextInput";
import Button from "../../../shared/ui-components/button/Button";
import {isMobile, isTablet} from "react-device-detect";

interface ForgotPasswordSendEmailProps {
    sendResetCode: (email: string) => void;
    error: string;
}

function ForgotPasswordSendEmail(props: ForgotPasswordSendEmailProps) {
    const {sendResetCode, error} = props;

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const emailRegex = new RegExp('[^@\\s]+@[^@\\s]+\\.[^@\\s]+');

    const onInputChange = (
        e: any,
        setValue: React.Dispatch<React.SetStateAction<string>>,
        setError: React.Dispatch<React.SetStateAction<string>>
    ) => {
        if (e.target?.value) {
            setError('');

            if (!emailRegex.test(e.target?.value)) {
                setError('Invalid email');
            }
        }
        setValue(e.target?.value);
        e.preventDefault();
    }

    const onInputBlur = (e: any, setError: React.Dispatch<React.SetStateAction<string>>, emptyErrorMessage: string) => {
        if (!e.target?.value) {
            setError(emptyErrorMessage);
        }
        e.preventDefault();
    }

    const submit = (e: any) => {
        sendResetCode(email);
        e.preventDefault();
    }

    useEffect(() => {
        setEmailError(error);
    }, [error])

    return (
        <div className={`ForgotPasswordSendEmail ${isMobile || isTablet ? 'mobile' : ''}`}>
            <div className={'ForgotPasswordSendEmail__title'}>Forgot Password</div>
            <div className={'ForgotPasswordSendEmail__description'}>Please enter you E-Mail here</div>
            <TextInput
                value={email}
                label={'Your Email Address'}
                icon={window.location.origin + '/assets/images/icons/Profile.svg'}
                iconInvalid={window.location.origin + '/assets/images/icons/Profile-invalid.svg'}
                error={emailError}
                type={'text'}
                onChange={(e) => onInputChange(e, setEmail, setEmailError)}
                onBlur={(e) => onInputBlur(e, setEmailError, 'Please enter your email in order to reset your password.')}
            ></TextInput>
            <div className={'ForgotPasswordSendEmail__hint'}>
                * In case you did not use your email as your username, you will
                need to contact your clinician to reset your password.
            </div>
            <Button text={'Continue'} disable={!email || !!emailError} onClick={submit}></Button>
        </div>
    );
}

export default ForgotPasswordSendEmail;
