import React from 'react';
import './Button.scss';

interface ButtonProps {
    text: string;
    onClick: (e: any) => void;
    icon?: string;
    disable?: boolean;
}

function Button(props: ButtonProps) {
    const {text, onClick, icon, disable} = props;

    return (
        <div className={`Button ${disable ? 'disable' : ''}`}>
            <button onClick={onClick}>
                {
                    icon && <img src={icon} alt={'icon'}/>
                }
                <span>{text}</span>
            </button>
        </div>
    );
}

export default Button;
