import React, {useEffect, useRef, useState} from 'react';
import './Login.scss';
import TextInput from "../../../shared/ui-components/text-input/TextInput";
import Button from "../../../shared/ui-components/button/Button";
import {Link} from "react-router-dom";
import {isMobile, isTablet} from "react-device-detect";
import {CastingRoutes} from "../../../App";
import ReCAPTCHA from "react-google-recaptcha";
import {StorageService} from "../../../shared/helpers/storageService";

interface LoginProps {
    submitLogin: (username: string, password: string, reCaptcha?: string) => void;
    error: string;
    showReCaptcha: boolean;
}

function Login(props: LoginProps) {
    const {submitLogin, error, showReCaptcha} = props;

    const [username, _setUsername] = useState('');
    const [password, _setPassword] = useState('');

    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [reCaptcha, _setReCaptcha] = useState('');

    // refs and setters need for getting values on Enter click
    // (you can't get state values from eventListener in init useEffect since it links to initial state values)
    const usernameInput = useRef(username);
    const passwordInput = useRef(password);
    const reCaptchaInput = useRef(reCaptcha);

    const setUsername = (value: string) => {
        usernameInput.current = value;
        _setUsername(value);
    }

    const setPassword = (value: string) => {
        passwordInput.current = value;
        _setPassword(value);
    }

    const setReCaptcha = (value: string) => {
        reCaptchaInput.current = value;
        _setReCaptcha(value);
    }

    const getRecaptchaKey = () => {
        return StorageService.getUserLocation() === 'Australia' ?
            process.env.REACT_APP_RECAPTCHA_KEY_AU :
            process.env.REACT_APP_RECAPTCHA_KEY;
    }

    const onInputChange = (
        e: any,
        setValue: (value: string) => void,
        setError: React.Dispatch<React.SetStateAction<string>>
    ) => {
        if (e.target?.value) {
            setError('');
        }
        setValue(e.target?.value);
        e.preventDefault();
    }

    const onInputBlur = (e: any, setError: React.Dispatch<React.SetStateAction<string>>, emptyErrorMessage: string) => {
        if (!e.target?.value) {
            setError(emptyErrorMessage);
        }
        e.preventDefault();
    }

    const submit = (e: any) => {
        submitLogin(usernameInput.current, passwordInput.current, reCaptchaInput.current);
        e.preventDefault();
    }

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                submit(event);
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    useEffect(() => {
        setPasswordError(error);
        setUsernameError(error);
    }, [error])

    return (
        <div className={`Login ${isMobile || isTablet ? 'mobile' : ''}`}>
            <div className={'Login__title'}>Welcome!</div>
            <div className={'Login__description'}>Log into XRHealth to start casting</div>
            <TextInput
                value={username}
                label={'Your Username *'}
                icon={'assets/images/icons/Profile.svg'}
                iconInvalid={'assets/images/icons/Profile-invalid.svg'}
                error={usernameError}
                type={'text'}
                onChange={(e) => onInputChange(e, setUsername, setUsernameError)}
                onBlur={(e) => onInputBlur(e, setUsernameError, 'Username is required.')}
            ></TextInput>
            <TextInput
                value={password}
                label={'Your Password *'}
                icon={'assets/images/icons/Lock.svg'}
                iconInvalid={'assets/images/icons/Lock-invalid.svg'}
                error={passwordError}
                type={'password'}
                onChange={(e) => onInputChange(e, setPassword, setPasswordError)}
                onBlur={(e) => onInputBlur(e, setPasswordError, 'Password is required.')}
            ></TextInput>
            <div className={`Login__forgot-password ${showReCaptcha ? 'showReCaptcha' : ''}`}>
                <Link to={`${CastingRoutes.FORGOT_PASSWORD}`}>Forgot your password</Link>
            </div>
            {
                showReCaptcha &&
                <ReCAPTCHA
                    sitekey={getRecaptchaKey() || ''}
                    onChange={(token) => {
                        setReCaptcha(token || '');
                    }}
                />
            }
            <Button text={'Login'} onClick={submit}></Button>
        </div>
    );
}

export default Login;
