import {StorageService} from "../helpers/storageService";

export const getBaseUrl = () => {
  return StorageService.getUserLocation() === 'Australia' ? process.env.REACT_APP_AU_SERVER_URL : process.env.REACT_APP_SERVER_URL;
}

export const login = (username: string, password: string, reCaptcha?: string) => {
  const body: Record<string, string> = {
    username,
    password,
    client_id : '2_5csjsiklbfwow4sogsg0kgwok0k0c44g8sgs0s0ogcsokco0k8',
    client_secret: '3qltxrybb8cg0gwk0s8s0wgk8g88c0gcc4wsccw0o84cccgo80',
    grant_type: 'password'
  };

  if (reCaptcha) {
    body['g-recaptcha-response'] = reCaptcha;
  }

  return fetch(getBaseUrl() + 'oauth/frontend/login', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async res => {
    const data = await res.json();

    // check for error response
    if (!res.ok) {
      return Promise.reject(data);
    }

    return data;
  });
}

export const sendRecoveryEmail = (email: string) => {
  return fetch(getBaseUrl() + 'oauth/frontend/recovery', {
    method: 'POST',
    body: JSON.stringify({email}),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async res => {
    const data = await res.json();

    // check for error response
    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;
  });
}

export const passwordRecovery = (email: string, code: string, recaptcha: any) => {
  let data = {
    grant_type: 'recovery',
    client_id : '2_5csjsiklbfwow4sogsg0kgwok0k0c44g8sgs0s0ogcsokco0k8',
    client_secret: '3qltxrybb8cg0gwk0s8s0wgk8g88c0gcc4wsccw0o84cccgo80',
    username: email,
    confirmation_token: code,
    'g-recaptcha-response': recaptcha
  };
  return fetch(getBaseUrl() + 'oauth/frontend/login', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async res => {
    const data = await res.json();

    // check for error response
    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;
  });
}

export const changePassword = (password: string) => {
  let data = {
    password: password,
    source: 'forgot',
    op: 'change_password'
  };
  return fetch(getBaseUrl() + `api/consumers/${StorageService.getUser()?.user_id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${StorageService.getAccessToken()}`
    }
  });
}
