import React, {useEffect} from 'react';
import './ContentLoader.scss';
import {CastingRoutes} from "../../../App";
import {useNavigate} from "react-router-dom";
import {ErrorsTypes} from "../../../errors-view-container/ErrorsViewContainer";

function ContentLoader() {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(`/${CastingRoutes.ERROR}/` + ErrorsTypes.CONNECTION_LOST, {replace: true})
        }, 1000 * 30);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="ContentLoader">
            <div className="ContentLoader__spin"><img src={"assets/images/loader.svg"} alt=""/></div>
            <div className="ContentLoader__icon"><img src={"assets/images/loader-icon.svg"} alt=""/></div>
        </div>
    );
}

export default ContentLoader;
