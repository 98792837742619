import React from 'react';
import './LoaderDots.scss';

function LoaderDots() {
    return (
        <div className="LoaderDots"></div>
    );
}

export default LoaderDots;
