import React, {createContext, useContext, useState} from "react";

export interface SnackbarNotificationProps {
    title: string;
    description: string;
    type: SnackbarTypes;
    icon?: string;
    time?: number; //ms
    content?: any;
    onClose?: (e: any) => void;
}

export enum SnackbarTypes {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

interface SnackbarContextInterface {
    settings: SnackbarNotificationProps,
    saveSnackbarSettings: (values: SnackbarNotificationProps) => void
}

export const SnackbarContext = createContext<SnackbarContextInterface>({
    settings: {
        title: '',
        description: '',
        type: SnackbarTypes.INFO,
    },
    saveSnackbarSettings: () => {
    }
});

export const SnackbarProvider = ({children, settings}: { children: any, settings: SnackbarNotificationProps }) => {
    const [currentSettings, setCurrentSettings] = useState(
        settings || {}
    );

    const saveSnackbarSettings = (values: SnackbarNotificationProps) => {
        setCurrentSettings(values);
    };

    return (
        <SnackbarContext.Provider
            value={{settings: currentSettings, saveSnackbarSettings}}
        >
            {children}
        </SnackbarContext.Provider>
    );
};

export const useSnackbarSettings = () => {
    return useContext(SnackbarContext);
};
