import React from 'react';
import './LoaderSpinner.scss';

function LoaderSpinner() {

    return (
        <div className="LoaderSpinner">
            <svg width="92px" height="92px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <animateTransform attributeName="transform" type="rotate" values="0 33 33;270 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite"/>
                    <circle fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" strokeDasharray="187" strokeDashoffset="610">
                        <animate attributeName="stroke" values="#23B9FF;#8E14DF;#45DD97;#195FE7" begin="0s" dur="5.6s" fill="freeze" repeatCount="indefinite"/>
                        <animateTransform attributeName="transform" type="rotate" values="0 33 33;135 33 33;450 33 33" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite"/>
                        <animate attributeName="stroke-dashoffset" values="187;46.75;187" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite"/>
                    </circle>
                </g>
            </svg>
        </div>
    );
}

export default LoaderSpinner;
