import React, {useEffect, useState} from 'react';
import './AuthHeader.scss';
import {StorageService} from "../../shared/helpers/storageService";
import Dropdown from "../../shared/ui-components/dropdown/Dropdown";
import {useNavigate} from "react-router-dom";
import {isMobile, isTablet} from "react-device-detect";
import {CastingRoutes} from "../../App";
import {checkLocation} from "../../shared/http/location.http";

function AuthHeader() {
    const navigate = useNavigate();
    const [dropdown, setDropdown] = useState(false);
    const [area, setArea] = useState<{title: string, icon?: string}>({
        title: 'Worldwide',
        icon: window.location.origin + '/assets/images/icons/earth-icon.svg'
    });

    const areas = [
        {
            title: 'Worldwide',
            icon: window.location.origin + '/assets/images/icons/earth-icon.svg'
        },
        {
            title: 'Australia',
            icon: window.location.origin + '/assets/images/icons/australia-flag.svg'
        },
    ]

    const onSelectArea = (e: any, areaIndex: number) => {
        setArea(areas[areaIndex]);
        StorageService.setUserLocation(areas[areaIndex].title)
        e.preventDefault();
    }

    useEffect(() => {
        checkLocation().then((res: {detected_country: string, expected_country: string}) => {
            if (res.detected_country === 'AU') {
                setArea(areas[1]);
                StorageService.setUserLocation(areas[1].title);
            }
        });
    }, []);

    return (
        <div className={`AuthHeader ${isMobile || isTablet ? 'mobile' : ''}`}>
            <img
                className={'AuthHeader__logo'}
                src={window.location.origin + '/assets/images/logo.svg'} alt={'Logo'}
                onClick={() => navigate(`/${CastingRoutes.LOGIN}`, {replace: true})}
            />
            <div className={'AuthHeader__area'}
                 aria-haspopup="menu"
                 aria-expanded={dropdown ? "true" : "false"}
                 onClick={() => setDropdown((prev) => !prev)}
            >
                <img className={'AuthHeader__area-icon'} src={area.icon} alt={'icon'}/>
                <span className={'AuthHeader__area-name'}>{area.title}</span>
                <img className={'AuthHeader__area-change'} src={window.location.origin + '/assets/images/icons/arrow-down.svg'} alt={'change_area'}/>
                <Dropdown
                    submenus={areas}
                    dropdown={dropdown}
                    onItemClick={onSelectArea}
                ></Dropdown>
                <div className={`dropdown-overlay ${dropdown ? 'show' : ''}`}></div>
            </div>
        </div>
    );
}

export default AuthHeader;
