import React from "react";
import './AppServerError.scss';
import AuthHeader from "../../login-container/AuthHeader/AuthHeader";


function AppServerError() {

    return <div className={'ErrorBoundaryContainer'}>
        <div className={'ErrorBoundary'}>
            <AuthHeader></AuthHeader>
            <div className={'ErrorBoundary__main'}>
                <div className={'ErrorBoundary__main__info'}>
                    <div className={'ErrorBoundary__main__info__illustration'}>
                        <img alt={'logo'} src={'assets/images/icons/server-issue.svg'}/>
                    </div>
                    <div className={'ErrorBoundary__main__info-title'}>Something went wrong.</div>
                    <div className={'ErrorBoundary__main__info-description'}>
                        There seems to be an issue with our servers.<br/>
                        Please try again later.
                    </div>
                </div>
                <div className={'ErrorBoundary__main__illustration'}>
                    <img alt={'logo'} src={'assets/images/Login_Illustration.png'}/>
                </div>
            </div>
        </div>
    </div>

}

export default AppServerError;
