import React, {useEffect, useState} from 'react';
import './ForgotPasswordCreateNew.scss';
import TextInput from "../../../shared/ui-components/text-input/TextInput";
import Button from "../../../shared/ui-components/button/Button";
import {useNavigate} from "react-router-dom";
import {CastingRoutes} from "../../../App";
import {isMobile, isTablet} from "react-device-detect";

interface ForgotPasswordCreateNewProps {
    resetPassword: (password: string) => void;
}

function ForgotPasswordCreateNew(props: ForgotPasswordCreateNewProps) {
    const {resetPassword} = props;

    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [confirmedPasswordError, setConfirmedPasswordError] = useState('');

    const passwordRegexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)

    const onPasswordChange = (e: any) => {
        if (e.target?.value) {
            setPasswordError('');
        }
        setPassword(e.target?.value);
        e.preventDefault();
    }

    const onPasswordBlur = (e: any) => {
        if (!e.target?.value) {
            setPasswordError('Please enter the code you received by email.');
            return;
        }
        if (!passwordRegexp.test(e.target.value)) {
            setPasswordError('Password must contain at least 8 characters, 1 capital letter, 1 lower case letter and 1 number.')
        }
        e.preventDefault();
    }

    const onConfirmPasswordChange = (e: any) => {
        const confirmPasswordValue = e.target?.value;

        if (confirmPasswordValue) {
            setPasswordError('');
        }

        setConfirmedPasswordError(confirmPasswordValue !== password ? 'Passwords do not match. Please try again.' : '');
        setConfirmedPassword(e.target?.value);
        e.preventDefault();
    }

    const submit = (e: any) => {
        resetPassword(password);
        e.preventDefault();
    }

    return (
        <div className={`ForgotPasswordCreateNew ${isMobile || isTablet ? 'mobile' : ''}`}>
            <div className={'ForgotPasswordCreateNew__title'}>Reset Password</div>
            <div className={'ForgotPasswordCreateNew__description'}>Reset Code accepted. Please choose a new password.
            </div>
            <div className={'ForgotPasswordCreateNew__hint'}>
                Password must contain at least 8 characters, 1 capital letter, 1 lower case letter and 1 number.
            </div>
            <TextInput
                value={password}
                label={'New Password'}
                icon={window.location.origin + '/assets/images/icons/Profile.svg'}
                iconInvalid={window.location.origin + '/assets/images/icons/Profile-invalid.svg'}
                error={passwordError}
                type={'password'}
                onChange={(e) => onPasswordChange(e)}
                onBlur={(e) => onPasswordBlur(e)}
            ></TextInput>
            <TextInput
                value={confirmedPassword}
                label={'Re-enter New Password'}
                icon={window.location.origin + '/assets/images/icons/Profile.svg'}
                iconInvalid={window.location.origin + '/assets/images/icons/Profile-invalid.svg'}
                error={confirmedPasswordError}
                type={'password'}
                onChange={(e) => onConfirmPasswordChange(e)}
            ></TextInput>
            <div className={'ForgotPasswordCreateNew__buttons'}>
                <Button text={'Cancel'} onClick={() => navigate(`/${CastingRoutes.LOGIN}`)}></Button>
                <Button
                    text={'Reset Password'}
                    disable={!password || !confirmedPassword}
                    onClick={submit}
                ></Button>
            </div>
        </div>
    );
}

export default ForgotPasswordCreateNew;
