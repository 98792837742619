import React from 'react';
import './CastingStatusConnected.scss';
import Button from "../../shared/ui-components/button/Button";

export interface CastingStatusConnectedProps {
    onStartClick: () => void,
}

function CastingStatusConnected(props: CastingStatusConnectedProps) {

    return (
        <div className={'CastingStatusConnected'}>
            <img alt={'Success'} className={'CastingStatusConnected__illustration'} src={'assets/images/casting-available-new.svg'} />
            <span className={'CastingStatusConnected__description'}>Casting is available!</span>
            <Button icon={'assets/images/casting-play.svg'} text={'Start Casting'} onClick={props.onStartClick}></Button>
        </div>
    );
}

export default CastingStatusConnected;
