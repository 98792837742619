import React, {useState} from 'react';
import './ForgotPasswordContainer.scss';
import ForgotPasswordSendEmail from "./ForgotPasswordSendEmail/ForgotPasswordSendEmail";
import ForgotPasswordResetCode from "./ForgotPasswordResetCode/ForgotPasswordResetCode";
import ForgotPasswordCreateNew from "./ForgotPasswordCreateNew/ForgotPasswordCreateNew";
import {changePassword, passwordRecovery, sendRecoveryEmail} from "../../shared/http/auth.http";
import AuthLoading from "../AuthLoading/AuthLoading";
import {SnackbarTypes, useSnackbarSettings} from "../../shared/ui-components/snackbar/SnackbarContext";
import * as Sentry from "@sentry/react";
import {useNavigate} from "react-router-dom";
import {StorageService} from "../../shared/helpers/storageService";
import {CastingRoutes} from "../../App";

enum RestorePasswordSteps {
    SEND_EMAIL,
    RESET_CODE,
    CREATE_NEW_PASSWORD,
    IS_LOADING,
}

function ForgotPasswordContainer() {
    const {saveSnackbarSettings} = useSnackbarSettings();
    const navigate = useNavigate();

    const [restorePasswordStep, setRestorePasswordStep] = useState<RestorePasswordSteps>(RestorePasswordSteps.SEND_EMAIL);
    const [loadingDescription, setLoadingDescription] = useState('Creating a password reset code. \n' +
        'Be right with you.')

    const [sendEmailError, setSendEmailError] = useState('');

    const [email, setEmail] = useState('');

    const sendResetCode = (email: string) => {
        setRestorePasswordStep(RestorePasswordSteps.IS_LOADING);

        sendRecoveryEmail(email).then(() => {
            setEmail(email);
            if (restorePasswordStep === RestorePasswordSteps.RESET_CODE) {
                saveSnackbarSettings({
                    title: '',
                    description: 'A new code has been sent to your email. Please enter it here',
                    type: SnackbarTypes.SUCCESS,
                    icon: window.location.origin + '/assets/images/icons/notification-success.svg',
                });
            }
            setRestorePasswordStep(RestorePasswordSteps.RESET_CODE);
        }).catch((e) => {
            if (e === 'You can request next recovery email after 24 hours.') {
                setRestorePasswordStep(RestorePasswordSteps.SEND_EMAIL);
                saveSnackbarSettings({
                    title: 'Password reset failed.',
                    description: e,
                    type: SnackbarTypes.ERROR,
                    icon: window.location.origin + '/assets/images/icons/notification-error.svg',
                });
            } else if (e === 'Incorrect username or password.') {
                setRestorePasswordStep(RestorePasswordSteps.SEND_EMAIL);
                setSendEmailError('The email address is incorrect. Please try again.');
            } else if (e.include('Incorrect value. You got')) {
                saveSnackbarSettings({
                    title: 'Reset code failed.',
                    description: e,
                    type: SnackbarTypes.ERROR,
                    icon: window.location.origin + '/assets/images/icons/notification-error.svg',
                });
            } else {
                setRestorePasswordStep(RestorePasswordSteps.SEND_EMAIL);
                saveSnackbarSettings({
                    title: 'Password reset failed.',
                    description: 'Please try again or contact our \n' +
                        'customer support.',
                    type: SnackbarTypes.ERROR,
                    icon: window.location.origin + '/assets/images/icons/notification-error.svg',
                });
            }

            Sentry.captureMessage(e);
        });
    }

    const submitResetCode = (code: string, recaptcha: string) => {
        setLoadingDescription('Verifying your reset code. Hang tight…');
        setRestorePasswordStep(RestorePasswordSteps.IS_LOADING);

        passwordRecovery(email, code, recaptcha)
            .then((res) => {
                const user = {
                    user_id: res.user_id,
                    role: res.role,
                    clinic_id: res.clinic_id,
                    clinic_name: res.clinic_name,
                    first_name: res.first_name,
                    last_name: res.last_name,
                    casting: res.casting,
                }

                //set user data for errors logs
                Sentry.setUser(user);

                StorageService.setAccessToken(res.access_token);
                StorageService.setUser(user);
                StorageService.setLoginTimestamp(new Date().getTime());

                setRestorePasswordStep(RestorePasswordSteps.CREATE_NEW_PASSWORD);
            })
            .catch((e) => {
                setRestorePasswordStep(RestorePasswordSteps.SEND_EMAIL);
                saveSnackbarSettings({
                    title: 'Password reset failed.',
                    description: 'Please try again or contact our \n' +
                        'customer support.',
                    type: SnackbarTypes.ERROR,
                    icon: window.location.origin + '/assets/images/icons/notification-error.svg',
                });

                Sentry.captureMessage(e);
            });

    }

    const resetPassword = (password: string) => {
        setLoadingDescription('Resetting your password. Hang tight…');
        setRestorePasswordStep(RestorePasswordSteps.IS_LOADING);

        changePassword(password).then(() => {
            saveSnackbarSettings({
                title: 'Well done!',
                description: 'Your Password successfully changed.\n' +
                    ' Enjoy XRHealth VR Casting!',
                type: SnackbarTypes.SUCCESS,
                icon: window.location.origin + '/assets/images/icons/notification-success.svg',
            });
            navigate(`/${CastingRoutes.STATUS}`);
        }).catch((e) => {
            saveSnackbarSettings({
                title: 'Password reset failed.',
                description: 'Please try again or contact our \n' +
                    'customer support.',
                type: SnackbarTypes.ERROR,
                icon: window.location.origin + '/assets/images/icons/notification-error.svg',
            });
            Sentry.captureMessage(e);
        })
    }

    return (
        <>
            {(() => {
                    switch (restorePasswordStep) {
                        case RestorePasswordSteps.SEND_EMAIL:
                            return <ForgotPasswordSendEmail
                                sendResetCode={sendResetCode}
                                error={sendEmailError}
                            ></ForgotPasswordSendEmail>;
                        case RestorePasswordSteps.RESET_CODE:
                            return <ForgotPasswordResetCode
                                submitResetCode={submitResetCode}
                                resendResetCode={() => sendResetCode(email)}
                            ></ForgotPasswordResetCode>;
                        case RestorePasswordSteps.CREATE_NEW_PASSWORD:
                            return <ForgotPasswordCreateNew resetPassword={resetPassword}></ForgotPasswordCreateNew>;
                        case RestorePasswordSteps.IS_LOADING:
                            return <AuthLoading description={loadingDescription}></AuthLoading>;
                        default:
                            return <ForgotPasswordSendEmail
                                sendResetCode={sendResetCode}
                                error={sendEmailError}
                            ></ForgotPasswordSendEmail>;
                    }
                }
            )()}
        </>
    );
}

export default ForgotPasswordContainer;
