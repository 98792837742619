import React, {useEffect} from "react";
import {StorageService} from "../helpers/storageService";

export function setWootricEvent(eventName: string) {
    setTimeout(() => {
        // @ts-ignore
        window.wootricSettings.event_name = eventName;
        // @ts-ignore
        window.WootricSurvey.run();
    }, 500);
}

function Wootric() {
    useEffect(() => {
        let setupScript = document.createElement('script');
        setupScript.type = 'text/javascript';
        setupScript.id = 'wootric-settings';
        setupScript.async = true;
        setupScript.innerHTML = `
      window.wootricSettings = {
        email: "${StorageService.getUser()?.user_id || StorageService.getMixpanelId() || ''}",
        created_at: ${Math.floor(new Date().getTime() / 1000)},
        account_token: "${process.env.REACT_APP_WOOTRIC_TOKEN}"
      };
    `;
        if (document.getElementById('wootric-settings') == null) {
            document.body.appendChild(setupScript);
        }

        // Beacon
        let beacon = document.createElement('script');
        beacon.type = 'text/javascript';
        beacon.id = 'wootric-beacon';
        beacon.async = true;

        beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
        beacon.onload = function() {
            // @ts-ignore
            window.wootric('run');
        };
        if (document.getElementById('wootric-beacon') == null) {
            document.body.appendChild(beacon)
        }
    }, [])

    return (<div></div>);
}

export default Wootric;
