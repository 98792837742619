import React from 'react';
import './TextInput.scss';

interface TextInputProps {
    onChange: (e: any) => void,
    onBlur?: (e: any) => void,
    label: string,
    icon?: string,
    iconInvalid?: string,
    value: string,
    error?: string,
    type: string,
}

function TextInput(props: TextInputProps) {
    const {
        label,
        icon,
        iconInvalid,
        onChange,
        onBlur,
        value,
        error,
        type,
    } = props;

    let touched = false;

    return (
        <div className="TextInput">
            <label className={value && 'active'}>
                {icon && !error && <span className={"icon"}><img alt={"name"} src={icon}/></span>}
                {iconInvalid && error && <span className={"icon"}><img alt={"name"} src={iconInvalid}/></span>}
                <input
                    onFocus={() => touched = true}
                    onBlur={onBlur}
                    onChange={onChange}
                    type={type}
                    className={"input"}
                />
                <span className="label">{label}</span>
                <div className={'validation-error'}>{error}</div>
            </label>
        </div>
    );
}

export default TextInput;
