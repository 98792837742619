import React from 'react';
import './CastingStatusNotConnected.scss';
import LoaderDots from "../../shared/ui-components/loader-dots/LoaderDots";

function CastingStatusNotConnected() {

    return (
        <div className={'CastingStatusNotConnected'}>
            <img alt={'Not available'} className={'CastingStatusNotConnected__image'} src={'assets/images/casting-not-available-new.svg'} />
            <span className={'CastingStatusNotConnected__header'}>Casting is not available. </span>
            <span className={'CastingStatusNotConnected__description'}>
                Make sure your VR device is turned on and <br/>
                connected to the internet.
            </span>
            <div className={'CastingStatusNotConnected__searching'}>
                <LoaderDots></LoaderDots>
                <span className={'CastingStatusNotConnected__searching__text'}>Searching for device...</span>
            </div>

        </div>
    );
}

export default CastingStatusNotConnected;
