import React from 'react';
import './VrConnectionLostError.scss';
import Button from "../../shared/ui-components/button/Button";
import {useNavigate} from "react-router-dom";
import {CastingRoutes} from "../../App";
import {StorageService} from "../../shared/helpers/storageService";

function VrConnectionLostError() {
    let navigate = useNavigate();

    const onTryAgainClick = () => {
        if (!StorageService.getAccessToken()) {
            navigate(`/${CastingRoutes.LOGIN}`, {replace: true})
        } else {
            navigate(`/${CastingRoutes.STATUS}`, { replace: true });
        }
    }

    return (
        <div className={'VrConnectionLostError'}>
            <img src={window.location.origin  + '/assets/images/bad-connection.svg'}/>
            <span className={'VrConnectionLostError__title'}>Something went wrong.</span>
            <span className={'VrConnectionLostError__description'}>
                Casting disconnected on the the VR device side. <br className={'desktop-hide'}/> Please check <br className={'mobile-hide'}/>
                your network connection and try again.</span>
            <Button text={'TRY AGAIN'} onClick={onTryAgainClick}></Button>
        </div>
    );
}

export default VrConnectionLostError;
