/* exported requestIceServers, sendAsyncUrlRequest, sendSyncUrlRequest, trace */

// Sends the URL request and returns a Promise as the result.
export function sendAsyncUrlRequest(method, url, body) {
    return sendUrlRequest(method, url, true, body);
}

// If async is true, returns a Promise and executes the xhr request
// async. If async is false, the xhr will be executed sync and a
// resolved promise is returned.
export function sendUrlRequest(method, url, async, body) {
    return new Promise(function(resolve, reject) {
        let xhr;
        const reportResults = function() {
            if (xhr.status !== 200) {
                reject(
                    Error('Status=' + xhr.status + ', response=' +
                        xhr.responseText));
                return;
            }
            resolve(xhr.responseText);
        };

        xhr = new XMLHttpRequest();
        if (async) {
            xhr.onreadystatechange = function() {
                if (xhr.readyState !== 4) {
                    return;
                }
                reportResults();
            };
        }

        xhr.open(method, url, async);
        xhr.send(body);

        if (!async) {
            reportResults();
        }
    });
}

// Returns a list of ICE servers after requesting it from the ICE server
// provider.
// Example response (iceServerRequestResponse) from the ICE server provider
// containing two TURN servers and one STUN server:
// {
//   lifetimeDuration: '43200.000s',
//   iceServers: [
//     {
//       urls: ['turn:1.2.3.4:19305', 'turn:1.2.3.5:19305'],
//       username: 'username',
//       credential: 'credential'
//     },
//     {
//       urls: ['stun:stun.example.com:19302']
//     }
//   ]
// }
export function requestIceServers(iceServerRequestUrl, iceTransports) {
    return new Promise(function(resolve, reject) {
        sendAsyncUrlRequest('POST', iceServerRequestUrl).then(function(response) {
            const iceServerRequestResponse = parseJSON(response);
            if (!iceServerRequestResponse) {
                reject(Error('Error parsing response JSON: ' + response));
                return;
            }
            if (iceTransports !== '') {
                filterIceServersUrls(iceServerRequestResponse, iceTransports);
            }
            trace('Retrieved ICE server information.');
            resolve(iceServerRequestResponse.iceServers);
        }).catch(function(error) {
            reject(Error('ICE server request error: ' + error.message));
            return;
        });
    });
}

// Parse the supplied JSON, or return null if parsing fails.
export function parseJSON(json) {
    try {
        return JSON.parse(json);
    } catch (e) {
        trace('Error parsing json: ' + json);
    }
    return null;
}

// Filter a peerConnection config to only contain ice servers with
// transport=|protocol|.
export function filterIceServersUrls(config, protocol) {
    const transport = 'transport=' + protocol;
    const newIceServers = [];
    for (let i = 0; i < config.iceServers.length; ++i) {
        const iceServer = config.iceServers[i];
        const newUrls = [];
        for (let j = 0; j < iceServer.urls.length; ++j) {
            const url = iceServer.urls[j];
            if (url.indexOf(transport) !== -1) {
                newUrls.push(url);
            } else if (
                url.indexOf('?transport=') === -1) {
                newUrls.push(url + '?' + transport);
            }
        }
        if (newUrls.length !== 0) {
            iceServer.urls = newUrls;
            newIceServers.push(iceServer);
        }
    }
    config.iceServers = newIceServers;
}

export function trace(text) {
    // This function is used for logging.
    if (text[text.length - 1] === '\n') {
        text = text.substring(0, text.length - 1);
    }
    if (window.performance) {
        const now = (window.performance.now() / 1000).toFixed(3);
        console.log(now + ': ' + text);
    } else {
        console.log(text);
    }
}
