import React, {useEffect, useRef, useState} from 'react';
import './Snackbar.scss';
import {useSnackbarSettings} from "./SnackbarContext";

function SnackbarNotification() {
    const {settings} = useSnackbarSettings();
    const [visibility, setVisibility] = useState(false);
    const [visibilityTimer, setVisibilityTimer] = useState<any>(null);

    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        showSnackbar();
    }, [settings]);

    const showSnackbar = () => {

        const timer = setTimeout(() => {
            setVisibility(false);
        }, settings.time ? settings.time : 5000);
        setVisibilityTimer(timer);

        if (visibility) {
            clearTimeout(visibilityTimer);
            setVisibilityTimer(null);
        }

        setVisibility(true);
    }

    const closeSnackbar = () => {
        setVisibility(false);
        clearTimeout(visibilityTimer);
    }

    return (
        <div id={'SnackbarNotification'}
             className={`SnackbarNotification ${settings.type} ${visibility ? 'show' : ''}`}>
            <div className={'SnackbarNotification__main'}>
                {
                    settings.icon &&
                    <div className={'SnackbarNotification__icon'}>
                        <img src={settings.icon} alt={settings.type}/>
                    </div>
                }
                <div className={'SnackbarNotification__info'}>
                    <span className={'SnackbarNotification__info__title'}>{settings.title}</span>
                    <span className={'SnackbarNotification__info__description'}>{settings.description}</span>
                </div>
                <div className={'SnackbarNotification__close'}>
                    <img src={window.location.origin  + '/assets/images/icons/close.svg'} alt={''}
                         onClick={settings.onClose ? settings.onClose : closeSnackbar}/>
                </div>
            </div>
            {
                settings.content && settings.content
            }
        </div>
    );
}

export default SnackbarNotification;
