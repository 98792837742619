import React from 'react';
import './DefaultCustomError.scss';
import Button from "../../shared/ui-components/button/Button";
import {useNavigate} from "react-router-dom";
import {CastingRoutes} from "../../App";
import {StorageService} from "../../shared/helpers/storageService";

interface DefaultCustomErrorInterface {
    title: string,
    description: string,
    icon?: string,
    content?: any,
}

function DefaultCustomError(props: DefaultCustomErrorInterface) {
    const {title, description, icon, content} = props;

    let navigate = useNavigate();

    const onBackClick = () => {
        if (!StorageService.getAccessToken()) {
            navigate(`/${CastingRoutes.LOGIN}`)
        } else {
            navigate(`/${CastingRoutes.STATUS}`);
        }
    }

    return (
        <div className={'DefaultCustomError'}>
            <img alt={'Not available'} src={icon ? icon : window.location.origin  + '/assets/images/casting-not-available.svg'} />
            <span className={'DefaultCustomError__title'}>{title}</span>
            <span className={'DefaultCustomError__description'}>{description}</span>
            {
                content && content
            }
            <Button text={'Back'} onClick={onBackClick}></Button>
        </div>
    );
}

export default DefaultCustomError;
