import React, {useEffect, useState} from 'react';
import './Header.scss';
import Dropdown from "../dropdown/Dropdown";
import {useNavigate} from "react-router-dom";
import {StorageService} from "../../helpers/storageService";
import {SnackbarTypes, useSnackbarSettings} from "../snackbar/SnackbarContext";
import {Mixpanel} from "../../services/mixpanel";
import {CastingRoutes} from "../../../App";
import {setWootricEvent} from "../../analytics/Wootric";


function Header() {
    let navigate = useNavigate();

    const areas = [
        {
            title: 'App Info',
            icon: window.location.origin + '/assets/images/icons/info.svg'
        },
        {
            title: 'Logout',
            icon: window.location.origin + '/assets/images/icons/logout.svg'
        },
    ]

    const [name, setName] = useState('');
    const [dropdown, setDropdown] = useState(false);
    const { saveSnackbarSettings } = useSnackbarSettings();

    useEffect(() => {
        const user = StorageService.getUser();
        if (user && user.first_name) {
            setName(user.first_name);
        }
    }, [])


    const onSelectArea = (e: any, areaIndex: number) => {
        if (areas[areaIndex].title === 'Logout') {
            Mixpanel.track('Log Out');
            StorageService.clear();
            setWootricEvent('Casting_Success');
            navigate(`/${CastingRoutes.LOGIN}`, { replace: true })
        } else if (areas[areaIndex].title === 'App Info') {
            saveSnackbarSettings({
                title: 'Portal Software Version',
                description: `${process.env.REACT_APP_VERSION}`,
                type: SnackbarTypes.INFO,
                icon: window.location.origin  + '/assets/images/icons/notification-info.svg',
            })
        }
        e.preventDefault();
    }

    return (
        <div className="Header">
            <img className={'Header__logo'} alt={'logo'} src={window.location.origin  + '/assets/images/logo.svg'}/>
            <div className={'Header__info'} onClick={() => setDropdown((prev) => !prev)}>
                <span>Hi, {name}</span>
                <img className={'Header__info__image'} alt={'logo'} src={window.location.origin  + '/assets/images/icons/User.svg'}/>
                <Dropdown
                    submenus={areas}
                    dropdown={dropdown}
                    onItemClick={onSelectArea}
                ></Dropdown>
                <div className={`dropdown-overlay ${dropdown ? 'show' : ''}`}></div>
            </div>
        </div>
    );
}

export default Header;
