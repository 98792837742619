import React, {useEffect, useRef, useState} from 'react';
import './Casting.scss';
import Button from "../../shared/ui-components/button/Button";
import {SnackbarTypes, useSnackbarSettings} from "../../shared/ui-components/snackbar/SnackbarContext";
import {Mixpanel} from "../../shared/services/mixpanel";
import {isMobile, isIOS, isAndroid} from "react-device-detect";
import {StorageService} from "../../shared/helpers/storageService";

interface CastingProps {
    stream: MediaStream | null;
    onStopStream: () => void;
}

function Casting(props: CastingProps) {
    const {stream, onStopStream} = props;

    const refVideo = useRef<HTMLVideoElement>(null);
    const refCastingContainer = useRef<HTMLDivElement>(null);

    const [fullScreen, setFullScreen] = useState(false);
    const [showFullScreenButton, setShowFullScreenButton] = useState(false);

    const {saveSnackbarSettings} = useSnackbarSettings();
    const isIFrame = window.location !== window.parent.location;

    const scaleForMobile = () => {
        if (refVideo.current && refCastingContainer.current && stream && isMobile) {
            // iOS does not support object-fit: fill css property
            let scaleX = refCastingContainer.current.clientHeight / refVideo.current.videoHeight;
            let scaleY = window.innerWidth / refVideo.current.videoWidth;

            if (window.matchMedia("(orientation: portrait)").matches) {
                scaleY = window.innerHeight / refVideo.current.videoWidth;
            } else {
                if (isAndroid) {
                    refVideo.current.style.transform = '';
                    refCastingContainer.current.classList.remove('ios-casting');
                    return;
                }

                if (window.visualViewport) {
                    scaleX = window.visualViewport.height / refVideo.current.videoHeight;
                    refCastingContainer.current.style.width = window.visualViewport.height + 'px';
                }
            }

            refVideo.current.style.transform = `scale(${scaleY}, ${scaleX})`;
            refCastingContainer.current.classList.add('ios-casting');
        }
    }

    useEffect(() => {
        const castingConfig = StorageService.getCastingConfig();

        if (castingConfig && castingConfig.hasOwnProperty('fullScreenButton') && !(isMobile && isIOS)) {
            setShowFullScreenButton(castingConfig.fullScreenButton);
        }

        scaleForMobile();
        window.addEventListener('resize', scaleForMobile);

        return (() => {
            window.removeEventListener('resize', scaleForMobile);
        })
    }, []);

    useEffect(() => {
        if (refVideo.current && !refVideo.current.src && !refVideo.current.srcObject && stream) {
            const mediaStreamClone = stream.clone();

            if ('srcObject' in refVideo.current) {
                refVideo.current.srcObject = mediaStreamClone;
            } else {
                // @ts-ignore
                refVideo.current.src = URL.createObjectURL(mediaStreamClone);
            }

            refVideo.current.onloadeddata = () => {
                scaleForMobile();
            }

            refVideo.current?.play().then().catch(console.log);

            saveSnackbarSettings({
                title: 'Well Done!',
                description: 'The VR casting process was \n' +
                    'completed successfully.',
                type: SnackbarTypes.SUCCESS,
                icon: 'assets/images/icons/notification-success.svg',
            })
        }
    }, [stream])

    const onFullscreenClick = () => {
        if (refCastingContainer.current) {
            if (isIFrame) {
                window.parent.postMessage('fullScreen', `${process.env.REACT_APP_WEB_PORTAL_URL}`);
                setFullScreen(true);
                return;
            }
            if (refCastingContainer.current.requestFullscreen) {
                refCastingContainer.current.requestFullscreen();
                // @ts-ignore
            } else if (refCastingContainer.current.webkitRequestFullscreen) { /* Safari */
                // @ts-ignore
                refCastingContainer.current.webkitRequestFullscreen();
                // @ts-ignore
            } else if (refCastingContainer.current.msRequestFullscreen) { /* IE11 */
                // @ts-ignore
                refCastingContainer.current.msRequestFullscreen();
            }

            Mixpanel.track('Resize Clicked', {
                Size: `Full Screen`,
            });

            setFullScreen(true);
        }
    }

    const onCloseFullscreenClick = () => {
        if (isIFrame) {
            window.parent.postMessage('closeFullScreen', `${process.env.REACT_APP_WEB_PORTAL_URL}`);
            setFullScreen(false);
            return;
        }
        if (document.exitFullscreen) {
            document.exitFullscreen();
            // @ts-ignore
        } else if (document.webkitExitFullscreen) { /* Safari */
            // @ts-ignore
            document.webkitExitFullscreen();
            // @ts-ignore
        } else if (document.msExitFullscreen) { /* IE11 */
            // @ts-ignore
            documentmsExitFullscreen();
        }

        Mixpanel.track('Resize Clicked', {
            Size: `Normal`,
        });

        setFullScreen(false);
    }

    return (
        <div className={'Casting'}>
            <div className={`Casting__container ${isMobile ? 'mobile-casting' : ''} ${isIFrame ? 'isIFrame' : ''}`}
                 ref={refCastingContainer}>
                {
                    (showFullScreenButton || isIFrame) && (fullScreen ?
                        <div className={'Casting__fullscreen-button'}>
                            <img
                                src={window.location.origin + '/assets/images/icons/close_full_screen.svg'}
                                alt={'exit'}
                                onClick={onCloseFullscreenClick}
                            />
                        </div> :
                        <div  className={'Casting__fullscreen-button'}>
                            <img
                                src={window.location.origin + '/assets/images/icons/full_screen.svg'}
                                alt={'full'}
                                onClick={onFullscreenClick}
                            />
                        </div>)
                }
                <video
                    ref={refVideo}
                    className={'Casting__video'}
                    style={{borderRadius: isIFrame && fullScreen ? '0' : '15px'}}
                    muted
                    playsInline
                ></video>
                <Button text={'Stop Casting'} onClick={onStopStream}
                        icon={'/assets/images/icons/casting_stop.svg'}></Button>
            </div>
        </div>
    );
}

export default Casting;
