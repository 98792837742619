import React, {useEffect} from 'react';
import './AuthContainer.scss';
import {Route, Routes, useNavigate} from "react-router-dom";
import ForgotPasswordContainer from "./ForgotPasswordContainer/ForgotPasswordContainer";
import AuthHeader from "./AuthHeader/AuthHeader";
import LoginContainer from "./LoginContainer/LoginContainer";
import {isMobile, isTablet} from "react-device-detect";
import {StorageService} from "../shared/helpers/storageService";
import {CastingRoutes} from "../App";

function AuthContainer() {
    let navigate = useNavigate();

    useEffect(() => {
        const token = StorageService.getAccessToken();

        if (token) {
            navigate(`/${CastingRoutes.STATUS}`);
        } else {
            StorageService.clear();
        }
    }, []);

    return (
        <div className={'AuthContainer'}>
            <div className={`AuthContainer__content ${isMobile || isTablet ? 'mobile' : ''}`}>
                <AuthHeader></AuthHeader>
                <div className={'AuthContainer__content__main'}>
                    <Routes>
                        <Route index element={<LoginContainer></LoginContainer>}/>
                        <Route path={`${CastingRoutes.FORGOT_PASSWORD}`} element={<ForgotPasswordContainer/>}/>
                    </Routes>
                    <div className={'AuthContainer__content__main__illustration'}>
                        <img alt={'logo'} src={window.location.origin  + '/assets/images/Login_Illustration.png'}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuthContainer;
