import React from 'react';
import './UnverifiedAccountError.scss';
import Button from "../../shared/ui-components/button/Button";
import {useNavigate} from "react-router-dom";
import {CastingRoutes} from "../../App";

function UnverifiedAccountError() {
    let navigate = useNavigate();

    const onTryAgainClick = () => {
        navigate(`/${CastingRoutes.LOGIN}`, { replace: true });
    }

    return (
        <div className={'UnverifiedAccountError'}>
            <img alt={'User unverified'} src={window.location.origin  + '/assets/images/unverified-user.svg'}/>
            <span className={'UnverifiedAccountError__title'}>Something went wrong.</span>
            <span className={'UnverifiedAccountError__description'}>
                The VR device could not verify your account.<br className={'desktop-hide'} /> Please check your <br className={'mobile-hide'}/>
                user details and try again. </span>
            <Button text={'TRY AGAIN'} onClick={onTryAgainClick}></Button>
        </div>
    );
}

export default UnverifiedAccountError;
