import {useEffect, useRef, useState} from 'react';
import './CastingStatusContainer.scss';
import CastingStatusConnected from "./casting-status-connected/CastingStatusConnected";
import CastingStatusConnecting from "./casting-status-connecting/CastingStatusConnecting";
import CastingStatusNotConnected from "./casting-status-not-connected/CastingStatusNotConnected";
import {usePubNub} from "pubnub-react";
import {useLocation, useNavigate} from "react-router-dom";
import {StorageService} from "../shared/helpers/storageService";
import {Mixpanel} from "../shared/services/mixpanel";
import * as Sentry from "@sentry/react";
import {CastingRoutes} from "../App";
import Pubnub from "pubnub";

enum CastingStatus {
    'NOT_CONNECTED',
    'CONNECTED',
    'CONNECTING'
}

function CastingStatusContainer() {
    const pubnub = usePubNub();
    const navigate = useNavigate();
    const state = useLocation().state as { roomId: string };

    const user = StorageService.getUser();

    const [status, setStatus] = useState<CastingStatus>(state && state.roomId ? CastingStatus.CONNECTED : CastingStatus.CONNECTING);

    const hereNowInterval = useRef<ReturnType<typeof setInterval>>();
    const presenceEventTime = useRef<number>(new Date().getTime());

    const onStartClick = () => {
        Mixpanel.track('Casting Started', {
            DeviceSN: `${user?.casting.deviceSn}`,
        });
        navigate(`/${CastingRoutes.CASTING}`);
    }

    const pubnubListener = {
        presence: (presenceEvent: Pubnub.PresenceEvent) => {
            const deviceSn = user?.casting.deviceSn;
            const timeFromLastEvent = new Date().getTime() - presenceEventTime.current;

            console.log(presenceEvent);

            if (presenceEvent.uuid === deviceSn && timeFromLastEvent >= 1000 * 10) {
                presenceEventTime.current = new Date().getTime();
                if (presenceEvent.action === 'leave' || presenceEvent.action === 'timeout') {
                    Sentry.captureMessage(`Pubnub connection has been lost (${presenceEvent.action} event)`);
                    setStatus(CastingStatus.NOT_CONNECTED);
                }
                if (presenceEvent.action === 'join') {
                    setStatus(CastingStatus.CONNECTED);
                }
            }
        }
    }

    useEffect(() => {
        const deviceSn = user?.casting.deviceSn;

        pubnub.addListener(pubnubListener);

        if (deviceSn) {
            checkDeviceAvailability(deviceSn);
        }

        return () => {
            pubnub.removeListener(pubnubListener)
            clearInterval(hereNowInterval.current);
        }
    }, []);

    const checkDeviceAvailability = (deviceSn: string): void => {
        const castingConfig = StorageService.getCastingConfig();
        let timesToCheck: number, checkingInterval: number;
        let counter = 0;

        if (castingConfig && castingConfig.deviceAvailability) {
            timesToCheck = castingConfig.deviceAvailability.timesToCheck ? +castingConfig.deviceAvailability.timesToCheck : 30;
            checkingInterval = castingConfig.deviceAvailability.checkingInterval ? +castingConfig.deviceAvailability.checkingInterval : 10;
        } else {
            timesToCheck = 30;
            checkingInterval = 10;
        }

        const interval = setInterval(() => {
            if (counter < timesToCheck) {
                counter++;
                pubnub.hereNow(
                    {
                        channels: [deviceSn]
                    },
                    function (status, response) {
                        if (
                            response &&
                            response.totalOccupancy &&
                            response.totalOccupancy >= 1 &&
                            response.channels[deviceSn].occupants.find((occupant) => {
                                return occupant.uuid === deviceSn;
                            })) {
                            setStatus(CastingStatus.CONNECTED);
                            clearInterval(hereNowInterval.current);
                        } else {
                            setStatus(CastingStatus.NOT_CONNECTED);
                        }
                    }
                );
            } else {
                clearInterval(hereNowInterval.current);
                Sentry.captureMessage('Checking headset presence time is expired');
                navigate(`/${CastingRoutes.ERROR}`);
            }
        }, 1000 * checkingInterval);
        hereNowInterval.current = interval;
    }

    return (
        <div className={'CastingStatusContainer'}>
            {(() => {
                    switch (status) {
                        case CastingStatus.CONNECTED:
                            return <CastingStatusConnected onStartClick={onStartClick}></CastingStatusConnected>;
                        case CastingStatus.NOT_CONNECTED:
                            return <CastingStatusNotConnected></CastingStatusNotConnected>;
                        default:
                            return <CastingStatusConnecting></CastingStatusConnecting>
                    }
                }
            )()}
        </div>
    );
}

export default CastingStatusContainer;
