import {User} from "../../login-container/LoginContainer/LoginContainer";

export enum StorageItems {
    USER = 'user',
    ACCESS_TOKEN = 'access_token',
    EXPIRE_DATE = 'expire_date',
    CASTING_CONFIG = 'casting_config',
    MIXPANEL_ID = 'mixpanel_id',
    USER_LOCATION = 'user_location',
    LOGIN_TIMESTAMP = 'login_timestamp',
    PUBNUB_ID = 'pubnub_id',
}

export const StorageService = {
    getUser: (): User | null => {
        const userJsonString = localStorage.getItem(StorageItems.USER);
        if (userJsonString) {
            return JSON.parse(userJsonString);
        }
        return null;
    },
    setUser: (user: object) => {
        return localStorage.setItem(StorageItems.USER, JSON.stringify(user));
    },
    getAccessToken: (): string => {
        return localStorage.getItem(StorageItems.ACCESS_TOKEN) || '';
    },
    setAccessToken: (token: string) => {
        return localStorage.setItem(StorageItems.ACCESS_TOKEN, token);
    },
    getCastingConfig: (): Record<string, any> | null => {
        const castingConfigString = sessionStorage.getItem(StorageItems.CASTING_CONFIG);
        if (castingConfigString) {
            return JSON.parse(castingConfigString);
        }
        return null;
    },
    setCastingConfig: (config: Record<string, any>) => {
        return sessionStorage.setItem(StorageItems.CASTING_CONFIG, JSON.stringify(config));
    },
    setPubnubId: (id: string) => {
        sessionStorage.setItem(StorageItems.PUBNUB_ID, id);
    },
    getPubnubId: (): string => {
        return sessionStorage.getItem(StorageItems.PUBNUB_ID) || '';
    },
    setMixpanelId: (id: string) => {
        sessionStorage.setItem(StorageItems.MIXPANEL_ID, id);
    },
    getMixpanelId: (): string => {
        return sessionStorage.getItem(StorageItems.MIXPANEL_ID) || '';
    },
    setUserLocation: (location: string) => {
        localStorage.setItem(StorageItems.USER_LOCATION, location);
    },
    getUserLocation: (): string => {
        return localStorage.getItem(StorageItems.USER_LOCATION) || '';
    },
    setLoginTimestamp: (time: number) => {
        localStorage.setItem(StorageItems.LOGIN_TIMESTAMP, `${time}`);
    },
    getLoginTimestamp: (): number => {
        const loginTimestampString = localStorage.getItem(StorageItems.LOGIN_TIMESTAMP);
        return loginTimestampString ? +loginTimestampString : 0;
    },
    get: (item: string) => {
        return localStorage.getItem(item);
    },
    set: (key: string, value: string) => {
        localStorage.setItem(key, value);
    },
    clear: () => {
        localStorage.clear();
    }
}
