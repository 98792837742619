import React from 'react';
import './ErrorsViewContainer.scss';
import DefaultCustomError from "./default-custom-error/DefaultCustomError";
import SessionExpiredError from "./session-expired-error/SessionExpiredError";
import UnverifiedAccountError from "./unverified-account-error/UnverifiedAccountError";
import VrConnectionLostError from "./vr-connection-lost-error/VrConnectionLostError";
import Header from "../shared/ui-components/header/Header";
import {Route, Routes, useLocation} from "react-router-dom";

export enum ErrorsTypes {
    DEFAULT = '',
    EXPIRE_LOGIN = 'expire-login',
    INVALID_USER = 'invalid-user',
    CONNECTION_LOST = 'connection-lost'
}

function ErrorsViewContainer() {
    const state = useLocation().state as { title: string, description: string };
    const {title, description} = state || {title: '', description: ''};
    const isIFrame = window.location !== window.parent.location;

    return (
        <div className={'ErrorsViewContainer'}>
            {!isIFrame && <Header></Header>}

            <div className={'ErrorsViewContainer__error-view'}>

                <Routes>
                    <Route index element={<DefaultCustomError
                        title={title || 'Casting is not available. '}
                        description={description || 'Make sure your VR device is turned on and \n connected to the internet.'}
                    ></DefaultCustomError>}/>
                    <Route path={ErrorsTypes.EXPIRE_LOGIN} element={<SessionExpiredError></SessionExpiredError>}/>
                    <Route path={ErrorsTypes.CONNECTION_LOST}
                           element={<VrConnectionLostError></VrConnectionLostError>}/>
                    <Route path={ErrorsTypes.INVALID_USER} element={<UnverifiedAccountError></UnverifiedAccountError>}/>
                    <Route path={'*'} element={<DefaultCustomError
                        title={title || 'Casting is not available. '}
                        description={description || 'Make sure your VR device is turned on and \n connected to the internet.'}
                    ></DefaultCustomError>}/>
                </Routes>
            </div>
        </div>
    );
}

export default ErrorsViewContainer;
