// @ts-ignore
import * as mixpanel from 'mixpanel-browser';
import {setUserProperties, trackEvent} from "../http/mixpanel.http";
import {getBaseUrl} from "../http/auth.http";
import {StorageService} from "../helpers/storageService";
import {isMobile, isTablet} from 'react-device-detect';

const superProperties = (): Record<string, string | number> => {
    const user = StorageService.getUser();
    const loginFormattedTime = new Date(StorageService.getLoginTimestamp()).toISOString();
    let deviceType;

    switch (true) {
        case isMobile: {
            deviceType = 'mobile';
            break;
        }
        case isTablet: {
            deviceType = 'tablet';
            break;
        }
        default: {
            deviceType = 'PC';
            break;
        }
    }

    const customSuperProperties = {
        'GameName': 'Casting',
        'AppVersion': process.env.REACT_APP_VERSION,
        'Server URL': getBaseUrl(),
        'OrganisationID': user && user.clinic_id ? user.clinic_id : '-1',
        'ConsumerID': user && user.user_id ? user.user_id : '-1',
        'ClinicianID': user && user.user_id ? user.user_id : '-1',
        'SessionID': `${user && user.user_id ? user.user_id : '-1'}D${loginFormattedTime}`,
        'Device Type': deviceType,
    }
    return {...mixpanel._.info.properties(), ...mixpanel.cookie.props, ...customSuperProperties};
}

const actions = {
    identify: (id: string) => {
        mixpanel.identify(id);
    },
    track: (name: string, props?: Record<string, string | number | boolean>) => {
        trackEvent(name, {...props, ...superProperties()});
    },
    people: {
        set: (props: Record<string, string | number>) => {
            setUserProperties({...props, ...superProperties()});
        },
    },
};

export const Mixpanel = actions;
