import {StorageService} from "../helpers/storageService";

export const trackEvent = (eventName: string, properties: object) => {
    return fetch(process.env.REACT_APP_MIXPANEL_API_URL + 'track', {
        method: 'POST',
        body: JSON.stringify({
            token: process.env.REACT_APP_MIXPANEL_ID,
            distinctId: StorageService.getMixpanelId(),
            event: eventName,
            properties
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then();
}

export const setUserProperties = (properties: object) => {
    return fetch(process.env.REACT_APP_MIXPANEL_API_URL + 'people', {
        method: 'POST',
        body: JSON.stringify({
            token: process.env.REACT_APP_MIXPANEL_ID,
            distinctId: StorageService.getMixpanelId(),
            properties
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then();
}

