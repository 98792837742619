import React, {useState} from 'react';
import './ForgotPasswordResetCode.scss';
import TextInput from "../../../shared/ui-components/text-input/TextInput";
import Button from "../../../shared/ui-components/button/Button";
import ReCAPTCHA from "react-google-recaptcha";
import {StorageService} from "../../../shared/helpers/storageService";
import {isMobile, isTablet} from "react-device-detect";

interface ForgotPasswordResetCodeProps {
    submitResetCode: (code: string, recaptcha: string) => void;
    resendResetCode: () => void;
}

function ForgotPasswordResetCode(props: ForgotPasswordResetCodeProps) {
    const {submitResetCode, resendResetCode} = props;

    const [resetCode, setResetCode] = useState('');
    const [resetCodeError, setResetCodeError] = useState('');

    const [recaptcha, setRecaptcha] = useState('');

    const resetCodeRegex = new RegExp(/^\d{6}$/);

    const getRecaptchaKey = () => {
        return StorageService.getUserLocation() === 'Australia' ?
            process.env.REACT_APP_RECAPTCHA_KEY_AU :
            process.env.REACT_APP_RECAPTCHA_KEY;
    }

    const onInputChange = (
        e: any,
        setValue: React.Dispatch<React.SetStateAction<string>>,
        setError: React.Dispatch<React.SetStateAction<string>>
    ) => {
        if (e.target?.value) {
            setError('');
        }
        if (e.target?.value && !resetCodeRegex.test(e.target.value)) {
            setError('Reset code is incorrect. Please try again.');
        }
        setValue(e.target?.value);
        e.preventDefault();
    }

    const onInputBlur = (e: any, setError: React.Dispatch<React.SetStateAction<string>>) => {
        if (!e.target?.value) {
            setError('Please enter the code you received by email.');
        }
        if (e.target?.value && !resetCodeRegex.test(e.target.value)) {
            setError('Reset code is incorrect. Please try again.');
        }
        e.preventDefault();
    }

    const resendCode = (e: any) => {
        resendResetCode();
        e.preventDefault();
    }

    const submit = (e: any) => {
        submitResetCode(resetCode, recaptcha);
        e.preventDefault();
    }

    return (
        <div className={`ForgotPasswordResetCode ${isMobile || isTablet ? 'mobile' : ''}`}>
            <div className={'ForgotPasswordResetCode__title'}>Forgot Password</div>
            <div className={'ForgotPasswordResetCode__description'}>A password reset code was sent to
                your E-mail. Please enter it here.</div>
            <TextInput
                value={resetCode}
                label={'Your Reset Code'}
                icon={window.location.origin + '/assets/images/icons/Profile.svg'}
                iconInvalid={window.location.origin + '/assets/images/icons/Profile-invalid.svg'}
                error={resetCodeError}
                type={'text'}
                onChange={(e) => onInputChange(e, setResetCode, setResetCodeError)}
                onBlur={(e) => onInputBlur(e, setResetCodeError)}
            ></TextInput>
            <ReCAPTCHA
                sitekey={getRecaptchaKey() || ''}
                onChange={(token) => {
                    setRecaptcha(token || '');
                }}
            />
            <div className={'ForgotPasswordResetCode__buttons'}>
                <Button text={'Resend Code'} onClick={resendCode}></Button>
                <Button text={'Continue'} disable={!resetCode || !recaptcha || !!resetCodeError} onClick={submit}></Button>
            </div>

        </div>
    );
}

export default ForgotPasswordResetCode;
