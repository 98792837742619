import React from 'react';
import './SessionExpiredError.scss';
import Button from "../../shared/ui-components/button/Button";
import {useNavigate} from "react-router-dom";
import {CastingRoutes} from "../../App";

function SessionExpiredError() {

    const navigate = useNavigate();

    const onLoginClick = () => {
        navigate(`/${CastingRoutes.LOGIN}`);
    }

    return (
        <div className={'SessionExpiredError'}>
            <img alt={'Expired'} src={window.location.origin  + '/assets/images/session-expired.svg'}/>
            <span className={'SessionExpiredError__title'}>
                Sorry, your casting session <br className={'desktop-hide'}/> has expired.
            </span>
            <span className={'SessionExpiredError__description'}>
                We logged you out to save your information private. <br/>
                Please press the button and log in again. </span>
            <Button text={'LOGIN'} onClick={onLoginClick}></Button>
        </div>
    );
}

export default SessionExpiredError;
