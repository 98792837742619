import React, {useEffect, useRef, useState} from 'react';
import './CastingContainer.scss';
import Casting from "./Casting/Casting";
import {Call} from "../shared/webRTC/call";
import ContentLoader from "../shared/ui-components/content-loader/ContentLoader";
import {useLocation, useNavigate} from "react-router-dom";
import {usePubNub} from "pubnub-react";
import {StorageService} from "../shared/helpers/storageService";
import {ErrorsTypes} from "../errors-view-container/ErrorsViewContainer";
import * as Sentry from "@sentry/react";
import {Mixpanel} from "../shared/services/mixpanel";
import {getWebRTCParams} from "../shared/http/config.http";
import {CastingRoutes} from "../App";
import {getBaseUrl} from "../shared/http/auth.http";
import {setWootricEvent} from "../shared/analytics/Wootric";
import {generateUUID} from "pubnub";

export interface CastingContainerProps {
    pubnubMessage: any,
    pubnubPresence: any
}

function CastingContainer(props: CastingContainerProps) {
    const {pubnubMessage, pubnubPresence} = props;

    const navigate = useNavigate();
    const location = useLocation();
    const pubnub = usePubNub();

    // instance of Call.js for WebRTC connection
    const [castingStream, setCastingStream] = useState<MediaStream | null>(null);

    const call = useRef<Call | null>();
    const roomId = useRef<string>('');
    const loadingTime = useRef<number>(0);
    const loadingTimeInterval = useRef<ReturnType<typeof setInterval>>();

    const user = StorageService.getUser();

    const onremotestreamadded = (stream: MediaStream) => {
        setCastingStream(stream);

        clearInterval(loadingTimeInterval.current);
        Mixpanel.track('Finished Loading', {
            'Loading Time': loadingTime.current,
        });
    };

    const oniceconnectionstatechange = (callInstance: Call) => {
        if (callInstance.pcClient_?.pc_.iceConnectionState === 'disconnected') {
            Sentry.captureMessage("ICE server has been disconnected");
            if (location.pathname === '/display') {
                navigate(`/${CastingRoutes.ERROR}/` + ErrorsTypes.CONNECTION_LOST);
            }
        }
    }

    const onSocketClose = (event: any, callInstance: Call) => {
        if (event && event.code) {
            callInstance.channel_.websocket_ = null;
            callInstance.channel_.registered_ = false;
            if (event.code !== 1000 && location.pathname === '/display') {
                Sentry.captureMessage("WebSocket server has been disconnected");
                navigate(`/${CastingRoutes.ERROR}/` + ErrorsTypes.CONNECTION_LOST);
            }
        }
    }

    const startWebRTCCall = () => {
        call.current?.start(roomId.current).then(() => {
            if (call.current)
                call.current.channel_.websocket_.close = (event: any) => onSocketClose(event, call.current!);
        })
    }

    // disconnect WSS and WebRTC connection
    const hangupWebRTCCall = (async: boolean = false) => {
        call.current?.hangup(async)?.then(() => {
            call.current = null;
            roomId.current = '';
        });
    }

    const sendConnectMessage = () => {
        setTimeout(() => {
            // send Start message to VR headset
            const message = {
                "type": 'Connect',
                "serverurl": getBaseUrl(),
                "castingtoken": user?.casting.castingToken,
                "roomid": roomId.current,
            }
            pubnub.publish({channel: `${user?.casting.deviceSn}`, message}).then();
        }, 1000)
    }

    const createWebRTCCallInstance = async () => {
        if (!call.current && roomId.current) {
            const params = await getWebRTCParams();

            // set correct wss data
            params.wss_post_url = "https://webrtc.xr.health:8089";
            params.wss_url = "wss://webrtc.xr.health:8089/ws";
            params.pc_config.iceServers = user?.casting?.iceServers;
            params.is_initiator = 'true';

            const callInstance = new Call(params);
            callInstance.onremotestreamadded = onremotestreamadded;
            callInstance.oniceconnectionstatechange = () => oniceconnectionstatechange(callInstance);
            callInstance.oncallerstarted = sendConnectMessage;

            // set function for using it hangup property on Stop casting
            call.current = callInstance;

            startWebRTCCall();
        }
    }

    const onStopStream = () => {
        // send Stop message to VR headset
        const message = {
            "type": 'Disconnect',
            "serverurl": getBaseUrl(),
            "castingtoken": user?.casting.castingToken,
        }
        pubnub.publish({channel: `${user?.casting.deviceSn}`, message}).then(() => {
            Mixpanel.track('Stop Casting', {
                'Device SN': `${user?.casting.deviceSn}`,
            });

            navigate(`/${CastingRoutes.STATUS}`, {state: {roomId: roomId.current}});
        });

        setWootricEvent('Casting_Success');
    }

    // generate new room id for WebRTC Peer connection
    useEffect(() => {
        if (!roomId.current) {
            const uuid = generateUUID();
            roomId.current = uuid;
        }

        // for mixpanel event
        let loadingInterval: ReturnType<typeof setInterval>;
        if (!castingStream) {
            loadingInterval = setInterval(() => {
                loadingTime.current++;
            }, 1000);
            loadingTimeInterval.current = loadingInterval;
        }

        createWebRTCCallInstance();

        return (() => {
            clearInterval(loadingTimeInterval.current);

            hangupWebRTCCall();
        })
    }, []);


    // handle pubnubMessage event from pubnub listener in Restricted
    useEffect(() => {
        if (pubnubMessage) {
            console.log(pubnubMessage, roomId.current, pubnub.getUUID());

            if (pubnubMessage.message.type === 'BadToken') {
                hangupWebRTCCall(true);
                Sentry.captureMessage("Invalid Casting Token");
                navigate(`/${CastingRoutes.ERROR}/` + ErrorsTypes.INVALID_USER);
            } else if (pubnubMessage.message.type === 'Connect' && pubnubMessage.publisher !== pubnub.getUUID()) {
                hangupWebRTCCall(true);
                Sentry.captureMessage("The casting is activated with user credentials on another device.");
                navigate(`/${CastingRoutes.ERROR}`, {
                    state: {
                        title: 'Something went wrong.',
                        description: 'The casting is activated with your credentials on another device. \n' +
                            ' Please disconnect from the other device and try again.',
                    }
                });
            }
        }
    }, [pubnubMessage]);

    // handle pubnubPresence event from pubnub listener in Restricted
    useEffect(() => {
        if (pubnubPresence) {
            console.log(pubnubPresence);
            if (pubnubPresence.uuid === user?.casting.deviceSn && (pubnubPresence.action === 'leave' || pubnubPresence.action === 'timeout')) {
                hangupWebRTCCall(true);
                Sentry.captureMessage(`Pubnub connection has been lost (${pubnubPresence.action} event)`);
                navigate(`/${CastingRoutes.ERROR}/` + ErrorsTypes.CONNECTION_LOST);
            }
        }
    }, [pubnubPresence]);

    return (
        <div className={'CastingContainer'}>
            {
                castingStream ?
                    <Casting stream={castingStream} onStopStream={onStopStream}/> :
                    <ContentLoader></ContentLoader>
            }
        </div>
    );
}

export default CastingContainer;
