export const getCastingConfig = () => {
    const envVariable = process.env.REACT_APP_ENV;

    let configEnv;
    switch (envVariable) {
        case 'dev': {
            configEnv = 'Dev';
            break;
        }
        case 'qa': {
            configEnv = 'QA';
            break;
        }
        case 'prod': {
            configEnv = 'Production';
            break;
        }
        default: {
            configEnv = 'Dev';
        }
    }

    return fetch(`https://media.xr.health/Casting/${configEnv}/web_vr_casting_config.json`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(async res => {
        const data = await res.json();

        // check for error response
        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;
    });
}

export const getWebRTCParams = () => {
    return fetch(process.env.REACT_APP_WEBRTC_ROOM_SERVER + '/params', {
        method: 'GET',
        headers: {
            'Content-Type': 'text/plain'
        }
    }).then(res => res.json()).then(params => {
        for (let paramKey in params) {
            if (typeof params[paramKey] === 'string' && params[paramKey][0] === '{') {
                params[paramKey] = JSON.parse(params[paramKey]);
            } else if (typeof params[paramKey] === 'string' && (params[paramKey] === 'true' || params[paramKey] === 'false')) {
                params[paramKey] = params[paramKey] === 'true';
            }
        }
        return params;
    });
}
