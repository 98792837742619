import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes, Route,
    Routes,
    useLocation,
    useNavigationType
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {StorageService} from "./shared/helpers/storageService";
// @ts-ignore
import * as mixpanel from 'mixpanel-browser';

Sentry.init({
    dsn: "https://6735e78afa4a45d7be8ce4597c63d09c@o193281.ingest.sentry.io/6601774",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        }),
    ],
    tracesSampleRate: 1.0,
});

mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
    loaded: (res: mixpanel) => {
        StorageService.setMixpanelId(res.get_distinct_id());
    }
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <SentryRoutes>
            <Route path="*" element={<App/>} />
        </SentryRoutes>
    </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
